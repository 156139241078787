import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MailLink from '../Contact/MailLink';
import PhoneLink from '../Contact/PhoneLink';
import "./NavBarTop.scss";

function NavBarTop() {

    const [Menu, setMenu] = useState(false);

    return (
        <div>
            <nav className="NavBarTop top navbar navbar-expand-lg navbar-light bg-light">
                <div className="container">


                    <div className="col-12 col-md d-flex flex-wrap align-items-center justify-content-between">
                        <Link to="/" className="d-flex flex-md-column flex-row">
                            <div className="navbar-brand" href="#"><Logo /></div>
                        </Link>

                        <div className="Links">
                            <div className="d-none d-lg-flex">
                                <MailLink />
                            </div>

                            <PhoneLink />
                        </div>

                    </div>
                </div>
            </nav>
            <div className="nav-top-push"></div>
        </div>

    )
}

export default NavBarTop

function Logo() {

    const logoImg = process.env.PUBLIC_URL + "/assets/images/logo/andorfer_logo_klein.png"

    return (
        <div>
            <img src={logoImg} />
        </div>
    )
}

const items = [
    // {
    //     title: "Videogalerie",
    //     uri: "/video-galerie",
    //     icon: <i class="fad fa-video"></i>,
    // },
    {
        title: "Über uns",
        uri: "/about",
        icon: <i class="fad fa-building"></i>,
    },
    {
        title: "Kompetenzen",
        uri: "/kompetenzen",
        icon: <i class="fad fa-list"></i>,
    },
    {
        title: "Kontakt",
        uri: "/kontakt",
        icon: <i class="fad fa-id-card"></i>,
    },
]