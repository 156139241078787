import React from "react"
import "./DefaultFader.scss"

function DefaultFader({ }) {
    return (
        <div className="Fader DefaultFader">
            {/* <NiceHeadingFader /> */}
        </div>
    )
}
export default DefaultFader