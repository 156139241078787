import React from 'react'
import TextPage from './TextPage'


function ImpressenPage() {
    return (
        <TextPage title="Impressum">
            <div className="mb-5">



            </div>
        </TextPage>
    )
}

export default ImpressenPage
