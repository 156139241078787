import React from "react";
import "./FeaturesBox.scss";
import DefaultFader from "./fader/DefaultFader";

function FeaturesBox() {
  return (
    <div className="FeaturesBox box">
      <div className="container d-flex flex-column">

        <div className="pb-4 mt-5">
          <span className="subHeading">Unsere Dienstleistungen</span>
          <h2>Alles rund ums Thema Klimaanlagen</h2>
        </div>


        <div className="row d-flex align-items-stretch">
          {features.map((i, k) => (
            <div className="FeatureBox p-1 p-lg-2 col-lg-6 mb-4 col-md-6 col-12 align-self-stretch">
              <div className="card p-0" key={k}>

                <div className="heading" style={{ background: `var(--${i.color})` }}>
                  <h3 style={{ color: `var(--${i.color}_)` }}>{i.title}</h3>
                </div>

                <div className="ratio ratio-16x9">
                  <div
                    className={"imageBox " + i.className}
                    style={{ backgroundImage: `url(${i.image})` }}
                  />
                </div>

                <div className="p-4">
                  <p>{i.text}</p>
                </div>

              </div>
            </div>
          ))}
        </div>
      </div >
      <DefaultFader />
    </div >
  );
}
export default FeaturesBox;

const klima2Img = process.env.PUBLIC_URL + "/assets/images/klima.jpg"
const klimaImg = process.env.PUBLIC_URL + "/assets/images/perfektesKlima.jpg"
const wärmeImg = process.env.PUBLIC_URL + "/assets/images/wäremeZuhause.jpg"
const luftImg = process.env.PUBLIC_URL + "/assets/images/angenehmeLuft.jpg"

let features = [
  {
    title: "Klima",
    color: "main",
    image: klimaImg,
    text: "Mono- und Multisplitt- sowie VRV-Klimatechnik mit Wand-, Decken-, Kassetten- oder Kanalgeräten…. Hier sind der Gestaltung Ihrer Anlage quasi keine Grenzen gesetzt. Das gilt ebenso für den gewerblichen wie auch den privaten Bereich. ",
  },
  {
    title: "Kälte",
    color: "blue",
    image: klima2Img,
    text: "Durch die vielzitierte Klimawende erfreut sich die Wärmepumpe einer wachsenden Beliebtheit. Hier unterstützen wir Sie (gerne auch über Ihren angestammten Heizungsbauer). \n\nOb Luft/Wasser-; Wasser/Wasser- oder auch die Luft/Luftwärmepumpe, wir sind mit den Systemen vertraut. Bei einer Wärmepumpe sollte immer die Förderung (bis zu 45% der Gesamtinvestition)",
  },
  {
    title: "Wärme",
    color: "red",
    image: wärmeImg,
    text: "Die klassische Kälte zählen wir natürlich zu unserem angestammten Leistungsspektrum. Kühlraum- und Tiefkühlraumkältetechnik und selbstverständlich ebenso die Kühl- und Tiefkühlzellen für Fleisch und Wurstwaren, für Gemüse oder Molkereiprodukte. \n\nSonderanlagen, Prozess- und Industriekälte, Spezialanwendung",
  },
  {
    title: "Luft",
    color: "yellow",
    image: luftImg,
    text: "Luftbefeuchtung (Dampfluftbefeuchter/Verdunstungsluftbefeuchter) Luftentfeuchtung(Kältetrockner/Drucklufttrocknung); Überdruckanlagen (Frischluftversorgung von innenliegenden Räumen); Luftfilterung und Luftreiniger.",
  },
];
