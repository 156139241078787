import React, { useState } from "react"
import TextareaAutosize from "react-textarea-autosize"
import "./ContactFormular.scss"

const prefix = "Hallo liebes Andorfer-KWKL Team,\n"
function ContactFormular({ }) {

    const [Name, setName] = useState("")
    const [Phone, setPhone] = useState("")
    const [Message, setMessage] = useState("")

    let phoneString = Phone ? "Telefon: " + Phone : null
    let renderMessage = prefix + Message + "\n\nMit freundlichen Grüßen\n" + Name + "\n\n" + phoneString

    let mailToUrl = "mailto:info@dashdesign.eu?subject=Kontaktanfrage%20per%20Formular&body=" + renderMessage

    mailToUrl = mailToUrl.replace("\n", "%0D%0A%0D%0A")

    return (
        <div className="ContactFormular">
            <div className="">

                <div className="inputBox">
                    <p><b>Name</b></p>
                    <input placeholder="[Ihr Name]" value={Name} onChange={e => setName(e.target.value)} />
                </div>

                <div className="inputBox">
                    <p><b>Telefonnummer (optional)</b></p>
                    <input
                        placeholder="[Ihre Telefonnummer]"
                        value={Phone}
                        onChange={e => setPhone(e.target.value)} />

                </div>

                <div className="inputBox">
                    <p><b>Ihre Nachricht</b></p>

                    <div className="textareaBox">
                        <span>{prefix}</span>
                        <TextareaAutosize
                            value={Message}
                            onChange={e => setMessage(e.target.value)}
                            placeholder="[Ihre Nachricht hier]"
                        />
                        <span>Mit freundlichen Grüßen<br />{Name}</span>
                    </div>
                </div>

                <div className="inputBox">
                    <a target="_blank" href={mailToUrl}>
                        <button>Per E-Mail senden</button>
                    </a>
                </div>
            </div>
        </div >
    )
}
export default ContactFormular