import React from "react"
import { Mail } from "../../config/ContactData"
import "./MailLink.scss"

function MailLink({ }) {
    return (
        <a href={Mail.url} className="MailLink">

            <i class="fas fa-envelope" />
            {Mail.lable}

        </a>
    )
}
export default MailLink