import React from "react"
import { Phone } from "../../config/ContactData"
import "./WhatsAppLink.scss"

function WhatsAppLink({ }) {
    return (
        <a target="_blank" className="WhatsAppLink" href={Phone.whatsappUrl}>
            <i class="fab fa-whatsapp" />
            Per WhatsApp schreiben
        </a>
    )
}
export default WhatsAppLink