export const Phone = {
    lable: "01234 56789",
    url: "tel:49123456789",
    whatsappUrl: "https://wa.me/4966349690997"
}
export const Mail = {
    lable: "info@andorfer-kwkl.de",
    url: "mailto:info@andorfer-kwkl.de"
}
export const Website = {
    lable: "andorfer-kwkl.de",
    url: "https://andorfer-kwkl.de"
}