import React, { useState } from 'react';
import ContactFormular from './ContactFormular';
import DefaultFader from './fader/DefaultFader';

function KontaktArea() {

    const [City, setCity] = useState("Gießen");

    return (
        <div id="kontakt" className="KontaktArea box">
            <div className="container mt-5 mb-5">
                <div>
                    <span className="subHeading">Unsere Kontaktdaten</span>
                    <h2>Kontaktieren Sie uns gerne!</h2>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6 col-lg-4 col-xl-3 p-4">
                        <p>
                            <b>Andorfer KWKL</b><br />
                            Tulpenweg 8<br />
                            35325 Mücke<br />
                        </p>
                        {/* <small>Klima Wärme Kälte Luft KG</small> */}
                        <p>
                            <b>Telefon:</b><br />
                            <a href="0123456789">01234 56789</a>
                        </p>
                        <p>
                            <b>E-Mail:</b><br />
                            <a href="mailto:info@andorfer-kwkl.de">info@andorfer-kwkl.de</a>
                        </p>
                        <hr className="d-block d-md-block" />
                    </div>

                    <div className="col-12 col-md-6 col-lg-8 col-xl-9 p-4">
                        <ContactFormular />
                    </div>
                </div>
            </div >
            <DefaultFader />
        </div >
    )
}

export default KontaktArea



