import React from 'react'
import TextPage from './TextPage'

function DatenschutzPage() {
    return (
        <TextPage title="Datenschutzrichtlinie">
            <div className="mb-5">
                <h2>Warte auf Kunde</h2>
            </div>
        </TextPage>
    )
}

export default DatenschutzPage
