import React from "react"
import WhatsAppLink from "../Contact/WhatsAppLink"
import "./BarBottom.scss"

function BarBottom({ }) {
    return (
        <div className="BarBottom d-block d-md-none">
            <WhatsAppLink />
        </div>
    )
}
export default BarBottom