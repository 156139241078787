import React from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import "./TextPage.css";

function TextPage(props) {
    const history = useHistory();
    return (
        <div className={props.className}>
            <Helmet>
                <title>{props.title}</title>
            </Helmet>
            <div className="TextPage">
                <div className="container">
                    <h1 className="big">
                        <i
                            title="Zurück"
                            onClick={() => history.goBack()}
                            className="fad fa-chevron-left"
                        ></i>{" "}
                        {props.title}
                    </h1>

                    <br />
                    {props.children}
                </div>
            </div>
        </div>
    );
}

export default TextPage;
