import React from 'react';
import FeaturesBox from '../components/FeaturesBox';
import KontaktArea from '../components/KontaktArea';
import VideoHeader from '../components/VideoHeader';
import DefaultFader from '../components/fader/DefaultFader';
import "./HomePage.scss";

function HomePage() {
    return (
        <div className="HomePage">

            <VideoHeader />

            <div className="box">
                <div className="container p-3">
                    <div className="row align-items-end pt-lg-5 pt-2">

                        <div className="col-12 col-lg-9 pe-5 mb-5">
                            <span className="subHeading">Über uns</span>

                            <h1>Wir schaffen ein angenehmes Raumklima</h1>

                            <p>In der Welt der Klima-, Wärme-, Kälte- und Lufttechnik sind wir seit über 40 Jahren zuhause. Der
                                Grundstein für unser Know-how wurde mit der fundierten Fachausbildung zum Kälteanlagenbauer
                                gelegt. Nach einigen Jahren der praktischen Berufserfahrung, folgte das Maschinentechnikerstudium
                                Fachrichtung Kältetechnik. Es folgten interessante Jahre in im Vertrieb. Hier wurde das Portfolio
                                erheblich erweitert. Zur Kälte- und Klimatechnik kamen die Wärmepumpen und die Lufttechnik
                                hinzu. Durch die Betreuung und durch die fachliche Unterstützung von den einschlägigen Fachfirmen
                                baute sich über die Jahre ein großer Erfahrungsschatz auf.</p>


                            <p>Durch unseren beständigen und verbindlichen Weg in unserer Berufswelt erfassen wir Ihre
                                Anforderungen und erarbeiten Lösungsvorschläge. Nach der Projektierung Ihrer individuellen Anlage
                                erhalten Sie ein verbindliches Angebot. Unsere daraus resultierenden Aufträge arbeiten wir
                                zuverlässig ab. Wir haben dabei stets das Kosten-/Nutzenverhältnis im Blick.</p>

                            <p>Wir verstehen uns als unterstützender Teil Ihrer Unternehmung. Unsere Berufung ist Sie mit Ihrer
                                von uns montierten Anlage voll umfänglich zufrieden zu stellen.</p>

                            <a href="#kontakt">
                                <button>Jetzt Kontakt aufnehmen <i class="far fa-chevron-right"></i></button>
                            </a>
                        </div>

                        <div className="col-12 col-lg-3 mb-5">
                            <div className="text-center">
                                <i className=" big fad fa-fan-table"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <DefaultFader />
            </div>

            <FeaturesBox />
            <KontaktArea />
        </div>
    )
}

export default HomePage
