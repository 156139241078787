import React from "react"
import { Phone } from "../../config/ContactData"
import "./PhoneLink.scss"

function PhoneLink({ className }) {
    return (
        <a className={"PhoneLink " + className} href={Phone.url}>
            <i class="fas fa-phone" />
            {Phone.lable}
        </a>
    )
}
export default PhoneLink