import React from "react"
import { Link } from "react-router-dom"
import PowerdBy from "../PowerdBy"
import "./Footer.scss"

function Footer({ }) {
    return (
        <footer className="Footer">

            <div className="container">
                <div className="row">

                    <div className="col-12 col-lg-6 mb-5">
                        <h3>Partner</h3>
                        <div className="items">
                            <div>
                                <a href="https://danaalbers.de/" target="_blank" rel="noopener noreferrer">danaalbers.de</a>
                                <a href="https://hessenapp.de" target="_blank" rel="noopener noreferrer">Hessen App GmbH</a>
                            </div></div>
                    </div>

                    <div className="col-12 col-lg-6 mb-5">
                        <h3>Rechtliches</h3>
                        <div className="items">
                            <Link to="/impressum">Impressum</Link>
                            <Link to="/datenschutz">Datenschutzerklärung</Link>
                        </div>
                    </div>
                </div>
                <hr />
                <div className="copyright d-flex flex-row justify-content-between flex-wrap">
                    <span className=" mb-2">
                        © 2024 Andorfer Klima Wärme Kälte Luft KG - Alle Rechte vorbehalten.
                    </span>
                    <PowerdBy />
                </div>
            </div>
        </footer>
    )
}
export default Footer