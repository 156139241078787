
import { Route, Switch } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import BarBottom from './components/bars/BarBottom';
import Footer from './components/bars/Footer';
import NavBarTop from './components/bars/NavBarTop';
import DatenschutzPage from './pages/DatenschutzPage';
import HomePage from './pages/HomePage';
import ImpressenPage from './pages/ImpressenPage';
import Page404 from './pages/Page404';

function App() {
  return (
    <div className="App">
      <BrowserRouter>


        <ScrollToTop />

        <NavBarTop />

        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="/datenschutz" component={DatenschutzPage} />
          <Route path="/impressum" component={ImpressenPage} />
          <Route path="/" component={Page404} />
        </Switch>

        <Footer />
        <BarBottom />
      </BrowserRouter>
    </div >
  );
}

export default App;
