import React from 'react'
import { Link } from 'react-router-dom'

function Page404() {
    return (
        <div className="container">
            <div className=" p-5">
                <h1>Unterseite nicht gefunden!</h1>
                <p>Fehlercode: 404</p>
                <h2><Link to="/">Zur Startseite</Link></h2>
            </div>
        </div>
    )
}
export default Page404