import React from 'react'
import "./VideoHeader.scss"

const Video = process.env.PUBLIC_URL + "/assets/videos/headerVideoByDashdesign.mp4"

function VideoHeader() {

    return (
        <div className="VideoHeader2">
            <div className="ratio ratio-16x9">
                <video autoplay="autoplay" muted="muted" loop="loop" className="w-100">
                    <source src={Video} type="video/mp4" />
                </video>
            </div>


        </div>
    )
}
export default VideoHeader